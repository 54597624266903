import config from './config';

export const fetchFromAirtable = async (table, params = {}) => {
  try {
    const endpoint = `https://api.airtable.com/v0/${config.airtableBaseId}/${encodeURIComponent(table)}`;
    
    const queryParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (key === 'sort') {
        queryParams.append('sort[0][field]', value[0].field);
        queryParams.append('sort[0][direction]', value[0].direction);
      } else {
        queryParams.append(key, value);
      }
    });
    
    const options = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${config.airtableApiKey}`,
        'Content-Type': 'application/json',
      }
    };

    const response = await fetch(`${endpoint}?${queryParams.toString()}`, options);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    return response.json();
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const updateAirtableRecord = async (table, recordId, fields) => {
  try {
    const endpoint = `https://api.airtable.com/v0/${config.airtableBaseId}/${encodeURIComponent(table)}/${recordId}`;

    const options = {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${config.airtableApiKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fields })
    };

    const response = await fetch(endpoint, options);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    return response.json();
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};
export const deleteAirtableRecord = async (table, recordId) => {
  try {
    const response = await table.destroy(recordId);
    return response;
  } catch (error) {
    console.error('Error deleting record:', error);
    throw error;
  }
};
export const uploadPhoto = async (file) => {
  try {
    const base64String = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.readAsDataURL(file);
    });

    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(`${config.apiUrl}/upload`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${config.airtableApiKey}`,
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error(`Upload failed: ${response.statusText}`);
    }

    const data = await response.json();
    return data.url;
  } catch (error) {
    console.error('Upload Error:', error);
    throw error;
  }
  
};