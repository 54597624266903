import React, { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext(null);

const MAKE_ENDPOINTS = {
  login: 'https://hook.eu2.make.com/iivrtysk01ubbmvbct7y0w4hsbyylqql',
  register: 'https://hook.eu2.make.com/daxeafhu8419vb9r85oih5x66va14eqm',
  getProfile: 'https://hook.eu2.make.com/n5cderg3nrlb7caeing8dmb8g6ns5voc'
};

export function UserProvider({ children }) {
  console.log('UserProvider is wrapping:', children); // Debug log to verify the provider is being used

  const [userEmail, setUserEmail] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check for existing user data
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      try {
        const parsedData = JSON.parse(storedUserData);
        setUserData(parsedData);
        setUserEmail(parsedData.email);
      } catch (err) {
        console.error('Error parsing stored user data:', err);
        localStorage.removeItem('userData'); // Clear invalid data
      }
    }
  }, []);

  const handleAuthResponse = (data) => {
    if (!data.success) {
      throw new Error(data.message || 'Authentication failed');
    }

    // Create a user object from the response
    const userObject = {
      email: data.email,
      password: data.password,
      // Add any other user data you want to store
    };

    // Store user data
    localStorage.setItem('userData', JSON.stringify(userObject));

    setUserData(userObject);
    setUserEmail(userObject.email);

    return data;
  };

  const login = async (email, password) => {
    try {
      setLoading(true);
      setError(null);

      const response = await fetch(MAKE_ENDPOINTS.login, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const text = await response.text(); // First get the response as text
      let data;
      
      try {
        data = JSON.parse(text); // Then try to parse it
      } catch (err) {
        console.error('Response text:', text);
        throw new Error('Invalid JSON response from server');
      }

      return handleAuthResponse(data);
    } catch (error) {
      setError(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const register = async (userData) => {
    try {
      setLoading(true);
      setError(null);
  
      console.log('Payload being sent to the webhook:', userData);
  
      const response = await fetch(MAKE_ENDPOINTS.register, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });
  
      const responseText = await response.text();
      console.log('Raw response:', responseText);
  
      let data;
      try {
        // Try to parse the response as JSON
        data = JSON.parse(responseText);
      } catch (err) {
        console.error('Invalid JSON in response:', responseText);
        throw new Error('Server returned invalid JSON response');
      }
  
      // Check if the response contains all required fields
      if (!data.email || !data.password || !data.name) {
        throw new Error('Registration response missing required fields');
      }
  
      // If everything is successful, handle the auth response
      return handleAuthResponse({
        success: true,
        email: data.email,
        password: data.password,
        name: data.name,
        role: data.role || 'user' // Provide default role if not present
      });
  
    } catch (error) {
      console.error('Registration error:', error);
      setError(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem('userData');
    setUserData(null);
    setUserEmail(null);
  };
  
  return (
    <UserContext.Provider 
      value={{ 
        userEmail, 
        userData, 
        login, 
        register, 
        logout,
        loading,
        error 
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  if (context === null) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}