import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

import { fetchFromAirtable } from '../utils/api';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Header from '../components/Header';

const localizer = momentLocalizer(moment);

export default function CalendarView() {
  const { userEmail } = useUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allEvents, setAllEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('all');

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const data = await fetchFromAirtable('meals', {
          filterByFormula: `{userEmail} = '${userEmail}'`,
        });

        const meals = data.records.map(record => ({
          title: record.fields.foodName || 'Unknown Food',
          start: new Date(record.fields.Date),
          end: new Date(record.fields.Date),
        }));

        setAllEvents(meals);
        setFilteredEvents(meals);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [userEmail]);

  const handleMonthChange = (e) => {
    const monthValue = e.target.value;
    setSelectedMonth(monthValue);

    if (monthValue === 'all') {
      setFilteredEvents(allEvents);
    } else {
      const filtered = allEvents.filter(event => {
        const eventMonth = moment(event.start).format('YYYY-MM');
        return eventMonth === monthValue;
      });
      setFilteredEvents(filtered);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Header />
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Calendar View</h1>
        <div className="mb-4">
          <label htmlFor="month-select" className="block text-sm font-medium text-gray-700">
            Select Month
          </label>
          <select
            id="month-select"
            value={selectedMonth}
            onChange={handleMonthChange}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option value="all">All Months</option>
            {Array.from({ length: 12 }, (_, i) => {
              const monthMoment = moment().month(i);
              const monthValue = monthMoment.format('YYYY-MM');
              return (
                <option key={monthValue} value={monthValue}>
                  {monthMoment.format('MMMM YYYY')}
                </option>
              );
            })}
          </select>
        </div>
        <Calendar
          localizer={localizer}
          events={filteredEvents}
          startAccessor="start"
          endAccessor="end"
          titleAccessor="title"
          style={{ height: 500 }}
        />
      </div>
    </div>
  );
}