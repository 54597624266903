const config = {
  airtableApiKey: import.meta.env.VITE_AIRTABLE_API_KEY,
  airtableBaseId: 'app2BlMGiQmiK4vgd',
  apiUrl: 'https://api.airtable.com/v0',
  tables: {
    users: 'User Information Table',
    dailyStats: 'Dailystats',
    meals: 'Meals',
  }
};

export default config;