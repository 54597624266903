import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { useUser } from '../contexts/UserContext';
import config from '../utils/config';
import { fetchFromAirtable, updateAirtableRecord } from '../utils/api';
import {
  ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

const NUTRIENT_COLORS = {
  calories: { bg: 'bg-blue-50', text: 'text-blue-600', progress: 'bg-blue-600' },
  protein: { bg: 'bg-green-50', text: 'text-green-600', progress: 'bg-green-600' },
  carbs: { bg: 'bg-yellow-50', text: 'text-yellow-600', progress: 'bg-yellow-600' },
  fat: { bg: 'bg-red-50', text: 'text-red-600', progress: 'bg-red-600' },
};

const MEAL_WINDOWS = {
  breakfast: { start: 6, end: 10, name: 'Breakfast' },
  lunch: { start: 11, end: 14, name: 'Lunch' },
  dinner: { start: 17, end: 21, name: 'Dinner' },
  snack: { start: 0, end: 23, name: 'Snack' },
};

export default function DashboardPage() {
  const navigate = useNavigate();
  const { userEmail } = useUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [dailyStats, setDailyStats] = useState(null);
  const [dailyMeals, setDailyMeals] = useState([]);
  const [isEditingTargets, setIsEditingTargets] = useState(false);
  const [editedTargets, setEditedTargets] = useState({
    dailyCalorieTarget: 0,
    dailyProteinTarget: 0,
    dailyCarbsTarget: 0,
    dailyFatTarget: 0,
  });
  const [weeklyData, setWeeklyData] = useState({
    '7 days': [],
    calories: [],
    protein: [],
    carbs: [],
    fat: [],
  });
  const [notifications, setNotifications] = useState([]);

  const addNotification = (message, type = 'info') => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, message, type }]);
    setTimeout(() => {
      setNotifications(prev => prev.filter(n => n.id !== id));
    }, 5000);
  };

  const getProgressPercentage = (current, target) => {
    if (!target) return 0;
    return Math.min(Math.round((current / target) * 100), 100);
  };

  const getToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Ensure local timezone is used
    return today;
  };

  const fetchDailyMeals = async () => {
    try {
      const storedUserData = localStorage.getItem('userData');
      const parsedUserData = JSON.parse(storedUserData);
      const email = parsedUserData?.email;
  
      if (!email) {
        throw new Error('No email found');
      }
  
      // Get the user's local date in ISO format
      const today = new Date();
      const localDate = today.toISOString().split('T')[0];
      console.log('Fetching meals for local date:', localDate);
  
      const mealsResponse = await fetchFromAirtable(config.tables.meals, {
        filterByFormula: `AND(LOWER({userEmail}) = LOWER('${email}'), DATETIME_FORMAT(SET_TIMEZONE({Date}, 'Asia/Jerusalem'), 'YYYY-MM-DD') = '${localDate}')`,
        sort: [{ field: 'Date', direction: 'desc' }],
      });
  
      if (mealsResponse.records) {
        const meals = mealsResponse.records.map((record) => ({
          id: record.id,
          foodName: record.fields.ShortDescription || 'Unnamed Meal',
          calories: parseFloat(record.fields['calories '] || 0),
          protein: parseFloat(record.fields.protein || 0),
          carbs: parseFloat(record.fields['carbs '] || 0),
          fat: parseFloat(record.fields['fat '] || 0),
          timestamp: record.fields.Date,
          photoUrl: record.fields.photo?.[0]?.url || '',
          timeString: new Date(record.fields.Date).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          }),
        }));
        console.log('Processed meals:', meals);
        setDailyMeals(meals);
      } else {
        setDailyMeals([]);
      }
    } catch (error) {
      console.error('Error fetching daily meals:', error);
    }
  };

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      const storedUserData = localStorage.getItem('userData');
      const parsedUserData = JSON.parse(storedUserData);
      const email = parsedUserData?.email;
  
      if (!email) {
        throw new Error('No email found');
      }
  
      const today = getToday();
      const localDate = today.toISOString().split('T')[0];
      console.log('Fetching stats for local date:', localDate);
  
      const userResponse = await fetchFromAirtable(config.tables.users, {
        filterByFormula: `LOWER({Email}) = LOWER('${email}')`,
        maxRecords: 1,
      });
  
      if (userResponse.records.length > 0) {
        const user = userResponse.records[0].fields;
        setUserData({
          ...user,
          recordId: userResponse.records[0].id,
          dailyCalorieTarget: parseFloat(user.dailyCalorieTarget) || 2000,
          dailyProteinTarget: parseFloat(user.dailyProteinTarget) || 120,
          dailyCarbsTarget: parseFloat(user.dailyCarbsTarget) || 250,
          dailyFatTarget: parseFloat(user.dailyFatTarget) || 50,
        });
      } else {
        throw new Error('User not found');
      }
  
      const statsResponse = await fetchFromAirtable(config.tables.dailyStats, {
        filterByFormula: `AND(LOWER({userEmail}) = LOWER('${email}'), DATETIME_FORMAT(SET_TIMEZONE({Date}, 'Asia/Jerusalem'), 'YYYY-MM-DD') = '${localDate}')`,
      });
  
      if (statsResponse.records.length > 0) {
        const stats = statsResponse.records[0].fields;
        setDailyStats({
          totalCalories: parseFloat(stats.TotalCalories) || 0,
          totalProtein: parseFloat(stats.TotalProtein) || 0,
          totalCarbs: parseFloat(stats.TotalCarbs) || 0,
          totalFat: parseFloat(stats.TotalFat) || 0,
        });
      } else {
        setDailyStats({
          totalCalories: 0,
          totalProtein: 0,
          totalCarbs: 0,
          totalFat: 0,
        });
      }
    } catch (error) {
      console.error('Error in fetchDashboardData:', error);
      setError(error.message);
      if (error.message === 'No email found' || error.message === 'User not found') {
        navigate('/auth');
      }
    } finally {
      setLoading(false);
    }
  };

  const getLastSevenDays = () => {
    const dates = [];
    const currentDate = new Date();
    for (let i = 6; i >= 0; i--) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() - i);
      date.setHours(0, 0, 0, 0);
      dates.push(date.toISOString().split('T')[0]);
    }
    return dates;
  };

 const fetchWeeklyData = async () => {
  try {
    const storedUserData = localStorage.getItem('userData');
    const parsedUserData = JSON.parse(storedUserData || '{}');
    const email = parsedUserData?.email;

    if (!email) {
      throw new Error('No email found');
    }

    const dates = getLastSevenDays();
    console.log('Fetching weekly data for dates:', dates);

    const dailyStatsResponse = await fetchFromAirtable(config.tables.dailyStats, {
      filterByFormula: `LOWER({userEmail}) = LOWER('${email}')`,
      sort: [{ field: 'Date', direction: 'desc' }],
    });

    if (!dailyStatsResponse || !dailyStatsResponse.records) {
      setWeeklyData({
        '7 days': dates,
        calories: Array(7).fill(0),
        protein: Array(7).fill(0),
        carbs: Array(7).fill(0),
        fat: Array(7).fill(0),
      });
      return;
    }

    const dailyTotals = dates.reduce((acc, date) => {
      acc[date] = { calories: 0, protein: 0, carbs: 0, fat: 0 };
      return acc;
    }, {});

    dailyStatsResponse.records.forEach((record) => {
      const recordDate = record.fields['Date']?.split('T')[0];
      if (dailyTotals[recordDate]) {
        dailyTotals[recordDate].calories = parseFloat(record.fields.TotalCalories) || 0;
        dailyTotals[recordDate].protein = parseFloat(record.fields.TotalProtein) || 0;
        dailyTotals[recordDate].carbs = parseFloat(record.fields.TotalCarbs) || 0;
        dailyTotals[recordDate].fat = parseFloat(record.fields.TotalFat) || 0;
      }
    });

    setWeeklyData({
      '7 days': dates,
      calories: dates.map((date) => Math.round(dailyTotals[date].calories)),
      protein: dates.map((date) => Math.round(dailyTotals[date].protein)),
      carbs: dates.map((date) => Math.round(dailyTotals[date].carbs)),
      fat: dates.map((date) => Math.round(dailyTotals[date].fat)),
    });
  } catch (error) {
    console.error('Error in fetchWeeklyData:', error);
  }
};



  const updateTargets = async () => {
    try {
      await updateAirtableRecord(config.tables.users, userData.recordId, {
        dailyCalorieTarget: editedTargets.dailyCalorieTarget,
        dailyProteinTarget: editedTargets.dailyProteinTarget,
        dailyCarbsTarget: editedTargets.dailyCarbsTarget,
        dailyFatTarget: editedTargets.dailyFatTarget,
      });

      setUserData(prev => ({
        ...prev,
        ...editedTargets
      }));

      setIsEditingTargets(false);
      addNotification('Targets updated successfully!', 'success');
    } catch (error) {
      console.error('Error updating targets:', error);
      addNotification('Failed to update targets', 'error');
    }
  };

  useEffect(() => {
    const checkAuth = () => {
      const userData = localStorage.getItem('userData');
  
      console.log('Checking user authentication:', userData);
  
      if (!userData) {
        console.log('User is not authenticated. Redirecting to login page.');
        navigate('/auth');
        return false;
      }
      return true;
    };
  
    if (checkAuth()) {
      fetchDashboardData();
    }
  }, [navigate]);

  // Add auto-refresh for meals
  useEffect(() => {
    const interval = setInterval(() => {
      fetchDailyMeals();
    }, 30000); // Refresh every 30 seconds

    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="flex flex-col items-center justify-center h-[calc(100vh-64px)]">
          <div className="w-16 h-16 border-4 border-blue-400 border-t-transparent rounded-full animate-spin"></div>
          <p className="mt-4 text-gray-600">Loading your dashboard...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="flex justify-center items-center h-[calc(100vh-64px)] text-red-600">
          Error: {error}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Welcome, {userData?.Name || 'User'}</h1>
          <img
            src="https://i.ibb.co/7Yvd0SJ/2268453-1-l-removebg-preview.png" // Update this path based on your directory structure
            alt="Calorify"
            className="w-1/2 mb-4"
            style={{ maxWidth: '25%' }}
          />
          {!isEditingTargets ? (
            <button
              onClick={() => setIsEditingTargets(true)}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Edit Targets
            </button>
          ) : (
            <div className="flex gap-2">
              <button
                onClick={() => setIsEditingTargets(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
              >
                Cancel
              </button>
              <button
                onClick={updateTargets}
                className="px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-lg hover:bg-green-700"
              >
                Save Targets
              </button>
            </div>
          )}
        </div>

        {/* Daily Stats Cards */}
        <div className="bg-white rounded-lg shadow p-6 mb-8">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Daily Stats</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            {/* Calories Card */}
            <div className="bg-blue-50 p-4 rounded-lg">
              <p className="text-sm text-blue-600">Calories</p>
              <div className="flex items-baseline">
                <p className="text-2xl font-bold">
                  {Math.round(dailyStats?.totalCalories || 0)}
                </p>
                <span className="text-sm text-gray-500 ml-1">
                  / {userData?.dailyCalorieTarget}
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                <div
                  className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                  style={{
                    width: `${getProgressPercentage(
                      dailyStats?.totalCalories || 0,
                      userData?.dailyCalorieTarget
                    )}%`
                  }}
                ></div>
              </div>
            </div>

            {/* Protein Card */}
            <div className="bg-green-50 p-4 rounded-lg">
              <p className="text-sm text-green-600">Protein</p>
              <div className="flex items-baseline">
                <p className="text-2xl font-bold">
                  {Math.round(dailyStats?.totalProtein || 0)}g
                </p>
                <span className="text-sm text-gray-500 ml-1">
                  / {userData?.dailyProteinTarget}g
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                <div
                  className="bg-green-600 h-2.5 rounded-full transition-all duration-300"
                  style={{
                    width: `${getProgressPercentage(
                      dailyStats?.totalProtein || 0,
                      userData?.dailyProteinTarget
                    )}%`
                  }}
                ></div>
              </div>
            </div>

            {/* Carbs Card */}
            <div className="bg-yellow-50 p-4 rounded-lg">
              <p className="text-sm text-yellow-600">Carbs</p>
              <div className="flex items-baseline">
                <p className="text-2xl font-bold">
                  {Math.round(dailyStats?.totalCarbs || 0)}g
                </p>
                <span className="text-sm text-gray-500 ml-1">
                  / {userData?.dailyCarbsTarget}g
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                <div
                  className="bg-yellow-600 h-2.5 rounded-full transition-all duration-300"
                  style={{
                    width: `${getProgressPercentage(
                      dailyStats?.totalCarbs || 0,
                      userData?.dailyCarbsTarget
                    )}%`
                  }}
                ></div>
              </div>
            </div>

            {/* Fat Card */}
            <div className="bg-red-50 p-4 rounded-lg">
              <p className="text-sm text-red-600">Fat</p>
              <div className="flex items-baseline">
                <p className="text-2xl font-bold">
                  {Math.round(dailyStats?.totalFat || 0)}g
                </p>
                <span className="text-sm text-gray-500 ml-1">
                  / {userData?.dailyFatTarget}g
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                <div
                  className="bg-red-600 h-2.5 rounded-full transition-all duration-300"
                  style={{
                    width: `${getProgressPercentage(
                      dailyStats?.totalFat || 0,
                      userData?.dailyFatTarget
                    )}%`
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>

        {/* Add New Meal Button */}
        <div className="flex justify-center mb-8">
          <button
            onClick={() => navigate('/TakePhotoPage')}
            className="px-6 py-3 text-base font-medium text-white bg-green-600 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 shadow-lg transition-all duration-200"
          >
            Add New Meal
          </button>
        </div>

        {/* Today's Meals */}
        <div className="bg-white rounded-lg shadow p-6 mb-8">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Today's Meals</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {dailyMeals.length === 0 ? (
              <p className="text-gray-500 col-span-full text-center">No meals recorded today</p>
            ) : (
              dailyMeals.map((meal) => (
                <div key={meal.id} className="border rounded-lg p-4">
                  <div className="flex flex-col h-full">
                    <div className="flex justify-between items-start mb-3">
                      <div className="flex-1">
                        <span className="text-sm text-gray-500 block mb-2">
                          {meal.timeString}
                        </span>
                        <h3 className="font-medium text-gray-900">
                          {meal.foodName}
                        </h3>
                      </div>
                      <div className="w-24 h-24 ml-4 flex-shrink-0">
                        {meal.photoUrl ? (
                          <img
                            src={meal.photoUrl}
                            alt={meal.foodName}
                            className="w-full h-full object-cover rounded-lg"
                          />
                        ) : (
                          <div className="w-full h-full bg-gray-200 rounded-lg flex items-center justify-center">
                            <span className="text-gray-400">No photo</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                      <div className="text-sm">
                        <span className="text-blue-600">{Math.round(meal.calories)}</span>
                        <span className="text-gray-600"> cal</span>
                      </div>
                      <div className="text-sm">
                        <span className="text-green-600">{Math.round(meal.protein)}</span>
                        <span className="text-gray-600">g protein</span>
                      </div>
                      <div className="text-sm">
                        <span className="text-yellow-600">{Math.round(meal.carbs)}</span>
                        <span className="text-gray-600">g carbs</span>
                      </div>
                      <div className="text-sm">
                        <span className="text-red-600">{Math.round(meal.fat)}</span>
                        <span className="text-gray-600">g fat</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Weekly Progress Chart */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Weekly Progress</h2>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={weeklyData['7 days'].map((date, index) => ({
                  date,
                  calories: weeklyData.calories[index],
                  protein: weeklyData.protein[index],
                  carbs: weeklyData.carbs[index],
                  fat: weeklyData.fat[index],
                }))}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area 
                  type="monotone" 
                  dataKey="calories" 
                  stroke="#8884d8" 
                  fill="#8884d8" 
                  opacity={0.3}
                  name="Calories"
                />
                <Area 
                  type="monotone" 
                  dataKey="protein" 
                  stroke="#82ca9d" 
                  fill="#82ca9d" 
                  opacity={0.3}
                  name="Protein (g)"
                />
                <Area 
                  type="monotone" 
                  dataKey="carbs" 
                  stroke="#ffc658" 
                  fill="#ffc658" 
                  opacity={0.3}
                  name="Carbs (g)"
                />
                <Area 
                  type="monotone" 
                  dataKey="fat" 
                  stroke="#ff8042" 
                  fill="#ff8042" 
                  opacity={0.3}
                  name="Fat (g)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}